<template>
    <div class="action-schedule">
        <div class="title f-bold" v-html="`일정 선택이 완료되었어요`" />
        <div class="body" v-html="body" />
    </div>
</template>

<script>
export default {
    name: 'ActionScheduleFinish',
    props: ['message'],
    computed: {
        content() {
            return this.$mustParse(this.message.content)
        },
        body() {
            return `${this.content.name} / ${this.content.job}님과의<br> 일정 선택이 완료되었어요`
        },
    },
}
</script>

<style scoped></style>
